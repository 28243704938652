import Header from 'components/Header';
import React, { useCallback, useMemo, useState } from 'react';
import { Button, MenuItem, Select } from '@klover/attain-design-system';
import * as Styled from './index.styles';

import DataTable from 'components/DataTable/DataTable';
import Field from 'components/Field';
import Link from 'components/Link';
import _debounce from 'lodash/debounce';
import getAuthorizedAxiosInstance from 'utils/getAuthorizedAxiosInstance';
import { API_BASE_ADDRESS } from 'pages/audienceManager/variables';
import { Box } from '@material-ui/core';
import { Chats, Plus } from '@phosphor-icons/react';
import { FormikProvider, useFormik } from 'formik';
import { Question } from 'interfaces/quickQuestionsManager';
import { QuickQuestionContext } from 'context';
import { useLocation } from 'react-router';

const QuickQuestionsManager = () => {
  const {
    setQuestionsLoading,
    setQuestions,
    questions,
    questionsLoading,
    setShowCreateQuickQuestion,
  } = React.useContext(QuickQuestionContext);
  const formik = useFormik({
    initialValues: {},
    onSubmit: (values) => console.log(values),
  });
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const [search, setSearch] = useState('');
  const [oprations, setOprations] = useState({
    page: 0,
    rowsPerPage: 25,
    sortBy: 'createdat',
    sortOrder: 'DESC',
    search: '',
    status: searchParams.get('status') || 'all',
  });

  const getQuestions = async () => {
    try {
      const axiosInstance = await getAuthorizedAxiosInstance();
      if (axiosInstance) {
        const res = await axiosInstance.get(
          `${API_BASE_ADDRESS}/questions?search=${encodeURIComponent(
            oprations?.search
          )}&page=${oprations?.page}&rowsPerPage=${
            oprations?.rowsPerPage
          }&sortBy=${oprations?.sortBy}&sortOrder=${
            oprations?.sortOrder
          }&status=${oprations?.status}`
        );
        setQuestions(res.data);
        setQuestionsLoading(false);
      } else {
        throw new Error('Failed to authorize request');
      }
    } catch (e) {
      console.log(e);
      setQuestionsLoading(false);
    }
  };

  React.useEffect(() => {
    setQuestionsLoading(true);
    getQuestions();
    setURL(oprations?.status);
  }, [oprations]);

  const setURL = (status: string) => {
    window.history.replaceState(
      null,
      '',
      `${location.pathname}?status=${status}`
    );
  };

  const debounceFn = useCallback(
    _debounce((value) => {
      setOprations({
        ...oprations,
        page: 0,
        search: value,
      });
    }, 500),
    [oprations]
  );

  return (
    <FormikProvider value={formik}>
      <Header
        title="Quick Questions Manager"
        action={
          <Button
            variant="contained"
            onClick={() => {
              setShowCreateQuickQuestion(true);
            }}
            startIcon={<Plus />}
            style={{ fontSize: 16 }}
          >
            Create Question
          </Button>
        }
      />
      <Box height="100%" padding={2}>
        <Styled.SearchArea>
          <>
            <Styled.Form>
              <Field
                value={search}
                name="searchField"
                style={{ height: 44, width: 415 }}
                onChange={(e: any) => {
                  setSearch(e.target.value);
                  debounceFn(e.target.value);
                }}
                placeholder={'Search Questions'}
                disabled={questionsLoading}
              />
            </Styled.Form>

            <Select
              name="selectField"
              id="questionsFilter"
              value={oprations?.status}
              onChange={(e) => {
                setOprations({
                  ...oprations,
                  page: 0,
                  status: e.target.value,
                });
                setURL(e.target.value);
              }}
              disabled={questionsLoading}
              style={{ width: 175 }}
            >
              <MenuItem key="all" value="all">
                Active/Inactive
              </MenuItem>
              <MenuItem key="active" value="active">
                Active
              </MenuItem>
              <MenuItem key="inactive" value="inactive">
                Inactive
              </MenuItem>
            </Select>
          </>
        </Styled.SearchArea>

        <QuickQuestionTable
          loading={questionsLoading}
          questions={questions}
          oprations={oprations}
          setOprations={setOprations}
        />
      </Box>
    </FormikProvider>
  );
};

export default QuickQuestionsManager;

const QuickQuestionTable = ({
  loading = true,
  questions,
  oprations,
  setOprations,
}: {
  questions: Question[];
  oprations: any;
  setOprations: any;
  loading: boolean;
}) => {
  const { sortBy, sortOrder } = oprations;
  const {} = React.useContext(QuickQuestionContext);

  const columns = [
    {
      title: 'Question',
      column: 'questiontext',
      isSorted: sortBy === 'questiontext',
      sortOrder: sortOrder,
      width: 600,
    },
    {
      title: 'Answer Type',
      column: 'answertype',
      isSorted: sortBy === 'answertype',
      sortOrder: sortOrder,
    },
    {
      title: 'Question ID',
      column: 'questionid',
      isSorted: sortBy === 'questionid',
      sortOrder: sortOrder,
      rightAlign: true,
    },
    {
      title: 'Created By',
      column: 'createdby',
      isSorted: sortBy === 'createdby',
      sortOrder: sortOrder,
    },
    {
      title: 'Created On',
      column: 'createdat',
      isSorted: sortBy === 'createdat',
      sortOrder: sortOrder,
      width: 120,
    },
    {
      title: 'Last Modified',
      column: 'updatedat',
      isSorted: sortBy === 'updatedat',
      sortOrder: sortOrder,
      width: 120,
    },
  ];

  const formattedRows = React.useMemo(() => {
    return questions?.map((x: any) => (
      <>
        <td>
          <Styled.QuestionTextContainer>
            <Styled.StyledBackgroundCircle
              active={x.isactive ? true : false}
              title={x.isactive ? 'Status: ON' : 'Status: OFF'}
            >
              <Chats style={{ width: 18, height: 18 }} />
            </Styled.StyledBackgroundCircle>
            <Link
              to={`/main/quick-questions-manager/questions/${x.questionid}`}
            >
              {x.questiontext}
            </Link>
          </Styled.QuestionTextContainer>
        </td>
        <td>
          <Styled.AnswerType>{x.answertype}</Styled.AnswerType>
        </td>
        <td>
          <Styled.QuestionId>{x.questionid}</Styled.QuestionId>
        </td>
        <td>
          <Styled.CreatedBy>{x.createdby}</Styled.CreatedBy>
        </td>
        <td>
          <Styled.CreatedOn>
            {x?.createdat
              ? new Date(parseInt(x.createdat)).toLocaleDateString()
              : null}
          </Styled.CreatedOn>
        </td>
        <td>
          <Styled.LastModified>
            {x?.updatedat
              ? new Date(parseInt(x.updatedat)).toLocaleDateString()
              : null}
          </Styled.LastModified>
        </td>
      </>
    ));
  }, [questions]);

  const count = useMemo(() => {
    const totalCount =
      questions && questions?.length > 0
        ? typeof questions[0].totalCount === 'string'
          ? Number(questions[0].totalCount)
          : questions[0].totalCount
        : 1;
    return totalCount;
  }, [questions]);

  const handleChangePage = (newPage: number) => {
    setOprations({
      ...oprations,
      page: newPage,
    });
  };

  const handleChangeRowsPerPage = (value: number) => {
    const newPageSize = parseInt(value, 10);
    setOprations({
      ...oprations,
      page: 0,
      rowsPerPage: newPageSize,
    });
  };

  const handleOnSortChange = (sortInfo: any) => {
    let sortOrder = 'DESC';
    if (oprations.sortBy === sortInfo.column) {
      sortOrder = oprations.sortOrder == 'ASC' ? 'DESC' : 'ASC';
    }
    setOprations({
      ...oprations,
      sortBy: sortInfo.column,
      sortOrder: sortOrder,
      page: 0,
    });
  };

  return (
    <>
      <DataTable
        loading={loading}
        columns={columns}
        rows={formattedRows || []}
        showRowsPerPageDropdown
        customRowsPerPageOptions={[25, 50, 100]}
        defaultRowsPerPage={25}
        onPaginationClick={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        onSortChange={handleOnSortChange}
        totalRows={count}
      />
    </>
  );
};
