import { gql } from '@apollo/client';

export const GET_CHANGE_LOGS = gql`
  query GetChangeLogs(
    $entityId: String!
    $systemName: String!
    $changedFields: [String!]
  ) {
    changeLogs(
      entityId: $entityId
      systemName: $systemName
      changedFields: $changedFields
    ) {
      changeId
      changedByEmail
      changedFields
      createdAt
      entityId
      newData
      previousData
      systemName
    }
  }
`;

export const GET_LIVERAMP_INTEGRATIONS = gql`
  query GetLiveRampIntegrations(
    $distributionManagerId: String
    $distributionManagerName: String
    $integrationId: String
    $integrationName: String
  ) {
    liveRampIntegrations(
      distributionManagerId: $distributionManagerId
      distributionManagerName: $distributionManagerName
      integrationId: $integrationId
      integrationName: $integrationName
    ) {
      integrations {
        integrationId
        integrationName
      }
      distributionManagers {
        distributionManagerId
        distributionManagerName
        integrationId
        integrationName
      }
    }
  }
`;
