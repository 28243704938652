import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import React, { useState } from 'react';
import { Button } from '@klover/attain-design-system';
import { Menu, MenuItem } from '@mui/material';
import { useFlags } from 'launchdarkly-react-client-sdk';

const CreateAudienceDropdown: any = (props: any) => {
  const { uiAudienceManagerLalModelingVisible = false } = useFlags();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { handleMenuItemClick } = props;
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        variant="contained"
        color="primary"
        endIcon={<ArrowDropDownIcon />}
        onClick={handleClick}
      >
        Create Audience
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuItem onClick={() => handleMenuItemClick('transunion')}>
          Create with TransUnion
        </MenuItem>
        {uiAudienceManagerLalModelingVisible && (
          <MenuItem onClick={() => handleMenuItemClick('attain')}>
            Create with In-House Modeling
          </MenuItem>
        )}
      </Menu>
    </div>
  );
};

export default CreateAudienceDropdown;
