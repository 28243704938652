import { TextField } from '@klover/attain-design-system';
import { TextFieldProps } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() =>
  createStyles({
    textArea: (minHeight: number) => ({
      '& > div': {
        minHeight: minHeight,
      },
      '& > div > textarea': {
        padding: '0px !important',
      },
    }),
  })
);

export const TextArea = ({ rows = 1, ...props }: TextFieldProps) => {
  const minHeight = rows * 30;
  const classes = useStyles(minHeight);

  return (
    <TextField multiline rows={rows} className={classes.textArea} {...props} />
  );
};

export default TextArea;
