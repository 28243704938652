import {
  AUDIENCE_HAS_BEEN_EDITED,
  NO,
  SAVE_AUDIENCE,
  YES,
} from '../../content';
import { Button } from '@klover/attain-design-system';
import { CircularProgress } from '@material-ui/core';
import { Dialog, Grid, theme } from '@klover/attain-design-system';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import {
  createAudience,
  saveAudience,
} from '../../pages/audienceManager/slice';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { useEffect, useState } from 'react';
import * as Styled from './index.styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: theme.spacing(3),
    },
    title: {
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: 23,
      paddingLeft: 16,
      paddingTop: 16,
    },
  })
);

interface Props {
  openUnsavedStateDialog: any;
  setActiveStep: any;
  setOpenUnsavedStateDialog: any;
}

const UnsavedChangesPrompt = ({
  openUnsavedStateDialog,
  setActiveStep,
  setOpenUnsavedStateDialog,
}: Props) => {
  const classes = useStyles();
  const [saveAudienceClicked, setSaveAudienceClicked] = useState(false);
  const dispatch = useAppDispatch();

  const entitiesById = useAppSelector((state) => {
    return state.audienceReducer.entities.audiences.byId;
  });

  const currentAudience = useAppSelector(
    (state) => state.audienceReducer.currentAudience
  );

  const currentConditions = useAppSelector((state) => {
    return state.audienceReducer.currentConditions;
  });

  const currentDimensions = useAppSelector((state) => {
    return state.audienceReducer.currentDimensions;
  });

  const currentDimensionGroups = useAppSelector((state) => {
    return state.audienceReducer.currentDimensionGroups;
  });

  const currentGroup = useAppSelector((state) => {
    return state.audienceReducer.currentGroup;
  });

  const audienceLoading = useAppSelector(
    (state) => state.audienceReducer.ui.audienceLoading
  );

  const handleSaveAudience = async () => {
    if (currentAudience) {
      const updatedAudience = {
        ...currentAudience,
        updatedAt: Date.now(),
        groupId: currentGroup?.id,
      };

      const normalizedObjects = {
        updatedAudience,
        currentDimensions,
        currentDimensionGroups,
        currentConditions,
      };

      const audienceExists = entitiesById[currentAudience.id];
      if (audienceExists) {
        dispatch(saveAudience(normalizedObjects));
      } else {
        dispatch(createAudience(normalizedObjects));
      }
    }
    setSaveAudienceClicked(true);
  };

  const close = () => {
    setOpenUnsavedStateDialog(false);
    setActiveStep(1);
  };

  const isAudienceLoading = audienceLoading.state === 'loading';

  useEffect(() => {
    if (saveAudienceClicked && !isAudienceLoading) {
      setActiveStep(1);
      setOpenUnsavedStateDialog(false);
    }
  }, [isAudienceLoading, saveAudienceClicked]);

  return (
    <>
      <Dialog
        fullWidth
        className={classes.root}
        onClose={close}
        open={openUnsavedStateDialog}
        headerID="unsavedChangesHeader"
        title=""
        header={
          <Styled.CardHeader>
            <Styled.CardTitle>{SAVE_AUDIENCE}</Styled.CardTitle>
          </Styled.CardHeader>
        }
        bodyID="unsavedChanges"
        footer={
          <>
            <Button
              color="primary"
              disableElevation
              onClick={handleSaveAudience}
              variant="contained"
              disabled={isAudienceLoading}
            >
              {YES}
              {isAudienceLoading && (
                <CircularProgress
                  size={24}
                  color="inherit"
                  style={{ marginLeft: 8 }}
                />
              )}
            </Button>
            <Button
              onClick={close}
              variant="outlined"
              disabled={isAudienceLoading}
            >
              {NO}
            </Button>
          </>
        }
        body={
          <Styled.StyledBodyContainer
            container
            spacing={theme.spacing.lg}
            direction="column"
          >
            <Grid item>
              <p>{AUDIENCE_HAS_BEEN_EDITED}</p>
            </Grid>
          </Styled.StyledBodyContainer>
        }
      />
    </>
  );
};
export default UnsavedChangesPrompt;
