import { version as CURRENT_UI_VERSION } from '../../package.json';
import { persistor } from 'redux/store';

/**
 * Check if current KAP_UI version is changed
 * @returns boolean
 */
const isVersionChanged = () => {
  const previousVersion = localStorage.getItem('app_version');
  return !previousVersion || previousVersion !== CURRENT_UI_VERSION;
};

/**
 * Setting latest KAP_UI version
 */
export const setLatestVersion = async () => {
  await persistor.flush();
  localStorage.setItem('app_version', CURRENT_UI_VERSION);
};

export default isVersionChanged;
