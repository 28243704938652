import styled from 'styled-components';
import tokens from 'tokens';
import { Autocomplete, Grid, theme } from '@klover/attain-design-system';

export const SelectAutoCompleteGroup = styled(Autocomplete)`
  width: 100%;
  display: inline-flex;
  & input {
    padding-top: 2px !important;
    padding-bottom: 2px !important;
    font-family: Calibre-Regular, sans-serif;
  }
`;

export const SelectAutoCompleteGroupOptions = styled.li`
  font-size: 16px;
  font-family: Calibre-Regular, sans-serif;
  word-break: break-all;
`;

export const CardHeader = styled.div`
  align-items: left;
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.xxs};
  width: 100%;
`;

export const CardTitle = styled.h2`
  ${theme.typography.headingSM};
  flex-grow: 1;
`;

export const StyledBodyContainer = styled(Grid)`
  span {
    ${theme.typography.headingXS};
  }
`;

export const Toggle = styled.div`
  margin: ${tokens.spacing.lg} 0;
`;

export const RadioGroup = styled.div`
  padding: ${tokens.spacing.md} 0;
`;
