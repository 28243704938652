import AudienceEditor from '../audienceManager/editor';
import AudienceManager from '../audienceManager';
import CustomAudiences from 'pages/customAudiences';
import CustomSnackBar from '../audienceManager/customSnackBar';
import Forecasting from '../forecasting';
import GlobalHeader from 'components/GlobalHeader/GlobalHeader';
import Help from '../help';
import Insights from '../insights';
import Login from 'pages/auth';
import Navigation from 'components/navigation';
import Notifications from '../notifications';
import OfferWallPages from './offerWallPages';
import Profile from 'components/profile';
import QuickQuestionPages from './quickQuestionPages';
import React, { useEffect, useState } from 'react';
import SalesLiftEditor from '../salesLiftStudyManager/editor';
import SalesLiftStudyCreator from '../salesLiftStudyManager/salesLiftStudyCreator';
import SalesLiftStudyManager from '../salesLiftStudyManager';
import Spinner from 'components/spinner';
import isVersionChanged, { setLatestVersion } from 'utils/versionCheck';
import tokens from 'tokens';
import {
  Badge,
  Box,
  Grid,
  Paper,
  Theme,
  Typography,
  createStyles,
  makeStyles,
} from '@material-ui/core';
import { ILoginReducer } from 'types/types';
import { IconButton, theme as kloverTheme } from '@klover/attain-design-system';
import { Link, Route, Switch } from 'react-router-dom';
import { LoadingStates } from 'constants';
import { Notification } from 'interfaces/notifications';
import { Notifications as NotificationsIcon } from '@material-ui/icons';
import { RESET_ALL_REDUCERS } from 'redux/actions';
import { RootStateOrAny, useSelector } from 'react-redux';
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';
import {
  removeNotification as removeAudienceNotification,
  setAudienceLoadingState,
} from '../audienceManager/slice';
import { removeNotification as removeOfferNotification } from '../offerWallManager/slice';
import { removeNotification as removeSalesLiftStudyNotification } from '../salesLiftStudyManager/slice';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { useHistory } from 'react-router';
import { useSigninCheck, useUser } from 'reactfire';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      height: '100vh',
    },
    title: {
      color: tokens.colorBodynegative,
      fontWeight: 600,
      lineHeight: '40px',
      paddingBottom: tokens.spacing.lg,
    },
    grid: {
      marginLeft: 250,
      flexGrow: 1,
      marginTop: 64,
    },
    notificationBadge: {
      padding: '8px',
      marginLeft: '-6px',
      color: '#DADEE2 !important',
      '&:hover': {
        color: 'white',
      },
      '&:focus': {
        color: 'white',
      },
    },
    profile: {
      position: 'fixed',
      bottom: 24,
      left: 20,
    },
    main: {
      backgroundColor: tokens.colorPagebg,
    },
    header: {
      gridArea: 'header',
      position: 'fixed',
      top: 0,
      height: '64px',
      width: '100%',
      zIndex: 2,
    },
    headerGrid: {
      display: 'grid',
    },
    nav: {
      gridArea: 'nav',
      height: 'auto',
      position: 'fixed',
      top: 64,
      width: 250,
      zIndex: 'auto',
      background: kloverTheme.colors.backgroundBrand,
      color: kloverTheme.colors.textInverted,
    },
  })
);

const Main: React.FC = () => {
  const dispatch = useAppDispatch();
  const { status, data: signInCheckResult } = useSigninCheck();
  const classes = useStyles();
  const history = useHistory();
  const { data: user } = useUser();
  const [LDProvider, setLDProvider] = useState<React.FC | null>(null);
  const [isLDLoading, setIsLDLoading] = useState(true);
  const isVersionUpdated = isVersionChanged();

  // Reducers
  const authData: ILoginReducer = useSelector(
    (state: RootStateOrAny) => state.loginReducer
  );

  const audienceManagerNotifications = useAppSelector(
    (state) => state.audienceReducer.ui.notifications
  );

  const currentNotifications = useAppSelector((state) => {
    return state.notificationsReducer.currentNotifications;
  });

  const unreadNotifications = currentNotifications?.reduce(
    (accumulator: number, currVal: Notification) => {
      return currVal.read ? accumulator : accumulator + 1;
    },
    0
  );

  const offerWallManagerNotifications = useAppSelector(
    (state) => state.offerWallReducer.ui.notifications
  );

  const salesLiftStudyManagerNotifications = useAppSelector(
    (state) => state.salesLiftStudiesReducer.ui.notifications
  );

  useEffect(() => {
    if (isVersionUpdated) {
      setLatestVersion();
      dispatch({ type: RESET_ALL_REDUCERS });
    }
    if (!authData.isLoggedIn) {
      history.push('/');
    }
  }, [authData, history]);

  useEffect(() => {
    (async () => {
      if (user) {
        try {
          const LDProviderComponent = await asyncWithLDProvider({
            clientSideID: process.env.LAUNCHDARKLY_PROJECT_CLIENT_SIDE_ID,
            user: {
              key: user?.uid,
              name: user?.email,
              email: user?.email,
            },
          });
          setLDProvider(() => LDProviderComponent);
          setIsLDLoading(false);
        } catch (error) {
          console.error('LaunchDarkly initialization failed:', error);
          setIsLDLoading(false);
        }
      }
    })();
    dispatch(setAudienceLoadingState({ state: LoadingStates.DONE }));
  }, [user]);

  React.useEffect(() => {
    dispatch(setAudienceLoadingState({ state: LoadingStates.DONE }));
  }, []);

  if (status === 'loading' || isLDLoading) {
    return <Spinner />;
  }

  if (signInCheckResult.signedIn === true) {
    const mainComponent = (
      <div className={classes.root}>
        <header className={classes.header}>
          <GlobalHeader />
        </header>
        <nav className={classes.nav}>
          <Navigation />
        </nav>
        <Grid container spacing={0}>
          <Grid item className={classes.grid}>
            <Box className={classes.main} mt={0} ml={0}>
              <Switch>
                <Route exact path="/main/audience-manager">
                  <AudienceManager />
                </Route>
                <Route path="/main/audience-manager/editor/:audienceid?">
                  <AudienceEditor />
                </Route>
                <Route exact path="/main/custom-audience-manager">
                  <CustomAudiences />
                </Route>
                <Route
                  path="/main/offer-wall-manager"
                  component={OfferWallPages}
                />
                <Route
                  path="/main/quick-questions-manager"
                  component={QuickQuestionPages}
                />
                <Route exact path="/main/sales-lift-studies">
                  <SalesLiftStudyManager />
                </Route>
                <Route path="/main/sales-lift-studies/creator">
                  <SalesLiftStudyCreator />
                </Route>
                <Route path="/main/sales-lift-studies/editor/:salesliftstudyid?">
                  <SalesLiftEditor />
                </Route>
                <Route path="/main/sales-lift-studies/results">
                  <div />
                </Route>
                <Route path="/main/forecasting">
                  <Forecasting />
                </Route>
                <Route path="/main/insights">
                  <Insights />
                </Route>
                <Route path="/main/help">
                  <Help />
                </Route>
                <Route path="/main/notifications">
                  <Notifications />
                </Route>
                <Route>
                  <AudienceManager />
                </Route>
              </Switch>
            </Box>
          </Grid>
        </Grid>
        {audienceManagerNotifications.map(({ state, message }, i) => (
          <CustomSnackBar
            index={i}
            trigger={true}
            severity={state === 'done' ? 'success' : 'error'}
            message={message || ''}
            key={i}
            removeNotificationFunction={removeAudienceNotification}
          />
        ))}
        {offerWallManagerNotifications.map(({ state, message }, i) => (
          <CustomSnackBar
            index={i}
            trigger={true}
            severity={state === 'done' ? 'success' : 'error'}
            message={message || ''}
            key={i}
            removeNotificationFunction={removeOfferNotification}
          />
        ))}
        {salesLiftStudyManagerNotifications.map(({ state, message }, i) => (
          <CustomSnackBar
            index={i}
            trigger={true}
            severity={state === 'done' ? 'success' : 'error'}
            message={message || ''}
            key={i}
            removeNotificationFunction={removeSalesLiftStudyNotification}
          />
        ))}
      </div>
    );

    if (LDProvider) {
      return <LDProvider>{mainComponent}</LDProvider>;
    }

    return mainComponent;
  } else {
    return <Login />;
  }
};

export default Main;
