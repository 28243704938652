import InlineAudienceExport from '../../pages/audienceManager/export/InlineAudienceExport';
import React, { useMemo, useState } from 'react';
import { Alert, theme } from '@klover/attain-design-system';
import { Box, Slider, Tooltip, Typography } from '@mui/material';
import { Info } from '@phosphor-icons/react';
import { SELECT_AUDIENCE_SIZE_INFORMATION_TEXT } from '../../content';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'row',
      gap: theme.spacing(3),
      padding: theme.spacing(2),
    },
    sliderBox: {
      border: '1px solid #ccc',
      borderRadius: theme.shape.borderRadius,
      padding: theme.spacing(2),
      flex: 3,
    },
    header: {
      fontFamily: 'Calibre-Semibold,sans-serif',
      fontWeight: 600,
      fontSize: 22,
      lineHeight: '32px',
    },
    body: {
      fontFamily: 'Calibre-Regular,sans-serif',
      fontSize: 16,
      marginTop: '4px',
    },
    slider: {
      width: '96% !important',
      marginLeft: '16px !important',
      maxWidth: '1060px',
      color: '#413762 !important',
      '& > .MuiSlider-markLabel': {
        fontFamily: 'Calibre-Regular,sans-serif',
      },
    },
    audienceDefinitionBox: {
      display: 'flex',
      flexDirection: 'column',
      border: '1px solid #ccc',
      borderRadius: theme.shape.borderRadius,
      padding: theme.spacing(2),
      flex: 1,
    },
    progressBarContainer: {
      display: 'flex',
      alignItems: 'center',
      marginTop: theme.spacing(2),
    },
    progressBar: {
      flexGrow: 1,
      position: 'relative',
      height: 8,
      borderRadius: 5,
      backgroundColor: '#e0e0e0',
    },
    progressIndicator: {
      position: 'absolute',
      height: '100%',
      borderRadius: 5,
    },
    labelText: {
      fontFamily: 'Calibre-Regular,sans-serif',
    },
  })
);

const ExportYourAudience = () => {
  const classes = useStyles();
  const [lalModelPrecision, setLalModelPrecision] = useState(1);

  const handleSliderChange = (
    event: any,
    newValue: React.SetStateAction<number>
  ) => {
    setLalModelPrecision(newValue);
  };

  const marks = Array.from({ length: 11 }, (_, i) => ({
    value: i,
    label: `${i}%`,
  }));

  const targetAudience = useMemo(() => {
    if (lalModelPrecision <= 3) {
      return 'specific.';
    } else if (lalModelPrecision <= 7) {
      return 'moderate.';
    } else {
      return 'fairly broad.';
    }
  }, [lalModelPrecision]);

  const progressIndicatorColor = useMemo(() => {
    if (lalModelPrecision <= 3) {
      return '#F288D7';
    } else if (lalModelPrecision <= 7) {
      return '#CAD31D';
    } else {
      return '#FF8266';
    }
  }, [lalModelPrecision]);

  return (
    <>
      <Box className={classes.container}>
        {/* Audience Size Slider */}
        <Box className={classes.sliderBox}>
          <span className={classes.header}>Select Audience Size</span>
          <div style={{ display: 'flex', marginTop: 8 }}>
            <Alert
              severity="info"
              style={{
                marginTop: theme.spacing.xs,
                marginBottom: theme.spacing.xs,
              }}
            >
              {SELECT_AUDIENCE_SIZE_INFORMATION_TEXT}
            </Alert>
          </div>

          <Slider
            className={classes.slider}
            value={lalModelPrecision}
            onChange={handleSliderChange}
            step={1}
            valueLabelDisplay="auto"
            marks={marks}
            max={10}
          />
        </Box>

        {/* Audience Definition */}
        <Box className={classes.audienceDefinitionBox}>
          <span className={classes.header}>
            Audience Definition{' '}
            <Tooltip title="Audience selection indicator">
              <span>
                <Info size={18} weight="fill" />
              </span>
            </Tooltip>
          </span>
          <span className={classes.body}>
            Your audience selection is {targetAudience}
          </span>

          {/* Progress Indicator */}
          <Box className={classes.progressBarContainer}>
            <Typography
              variant="body2"
              className={classes.labelText}
              style={{ marginRight: '8px' }}
            >
              Specific
            </Typography>
            <Box className={classes.progressBar}>
              <Box
                className={classes.progressIndicator}
                style={{
                  width: `${lalModelPrecision * 10}%`,
                  backgroundColor: progressIndicatorColor,
                }}
              />
            </Box>
            <Typography
              variant="body2"
              className={classes.labelText}
              style={{ marginLeft: '8px' }}
            >
              Broad
            </Typography>
          </Box>
        </Box>
      </Box>
      <InlineAudienceExport
        lalModelType="attain"
        lalModelPrecision={lalModelPrecision}
      />
    </>
  );
};

export default ExportYourAudience;
