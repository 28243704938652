import styled from 'styled-components';

import { theme } from '@klover/attain-design-system';

export const StatusComplete = styled.div`
  ${theme.typography.bodyLG};
  align-items: center;
  color: ${theme.colors.successForeground};
  display: flex;
  gap: ${theme.spacing.xs};
`;

export const StatusErrorsDetected = styled(StatusComplete)`
  color: ${theme.colors.text};

  svg {
    color: ${theme.colors.errorForeground};
  }
`;

export const StatusInProgress = styled(StatusComplete)`
  color: ${theme.colors.text};

  svg {
    color: ${theme.colors.warningForeground};
  }
`;

export const StatusNotStarted = styled(StatusComplete)`
  color: ${theme.colors.textTertiary};
`;
