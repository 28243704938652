import _ from 'lodash';
import nanoid from 'utils/nanoid';
import {
  AmountOptionKey,
  CountOptionKey,
  FilterOperatorKey,
  TimeframeOperatorKey,
  TimeframeOptionKey,
} from './interfaces';
import { PayloadAction, createSlice, current } from '@reduxjs/toolkit';
import { StringMap } from 'interfaces';
import { getNormalizedObject } from 'utils/normalizedObject';

import {
  NormalizedObjects,
  initialNormalizedObject,
} from 'interfaces/entities';

import { LoadingProps, LoadingPropsObject } from 'interfaces/ui';

export interface Audience {
  id: string;
  name: string;
  totalSize: number;
  dimensions: Dimension['id'][];
  dimensionGroups: DimensionGroup['dimension1Id'][];
  createdAt: number;
  updatedAt: number;
  totalcount: number;
  lalModelType: string;
  inHouseModelingStatus: any;
}

export interface AudienceGroup {
  updated_at: any;
  id: string;
  name: string;
  audiences: Audience['id'][];
}

export interface Condition {
  id: string;
  sort: number;
  description: string;
  operator:
    | ConditionOperator
    | ConditionComparisonOperator
    | ConditionPatternOperatorKey;
  qualifiers: string | string[];
  audienceSize: number;
  questionCondition?: DimensionCategoryCondition;
}

export interface Surveys {
  surveys?: Survey[];
}

export type DimensionCategoryCondition =
  | ConditionBanking
  | ConditionDemographic
  | ConditionMerchant
  | ConditionProduct
  | ConditionTransaction
  | ConditionSurvey;

export type ConditionOption =
  | ConditionBankingOption
  | ConditionDemographicOption
  | ConditionMerchantOption
  | ConditionProductOption
  | ConditionTransactionOption
  | ConditionSurveyOption;

export interface ConditionOptionMap {
  [key: string]: string | StringMap;
}

export interface ConditionBanking extends Condition {
  option: ConditionBankingOption;
}

export const ConditionBankingOptions: ConditionOptionMap = {
  institutionName: 'bank',
  balance: {
    query: 'balance',
    operatorType: 'comparison',
  },
};
export const ConditionBankingKeys = Object.keys(ConditionBankingOptions);
export type ConditionBankingOption = typeof ConditionBankingKeys[number];

export interface ConditionDemographic extends Condition {
  option: ConditionDemographicOption;
}

export const ConditionDemographicOptions: ConditionOptionMap = {
  age: {
    query: 'age',
    operatorType: 'comparison',
  },
  city: 'city',
  state: 'state',
  zipCode: 'zip',
  gender: 'gender',
  race: 'race',
  education: 'education',
  householdType: 'hhtype',
  numberInHousehold: {
    query: 'hhnum',
    operatorType: 'comparison',
  },
  numberOfKids: {
    query: 'childnum',
    operatorType: 'comparison',
  },
  relationshipStatus: 'relstatus',
  sexualOrientation: 'orient',
};
export const ConditionDemographicKeys = Object.keys(
  ConditionDemographicOptions
);
export type ConditionDemographicOption =
  typeof ConditionDemographicKeys[number];

export interface ConditionMerchant extends Condition {
  option: ConditionMerchantOption;
}

export const ConditionMerchantOptions = [''] as const;
export type ConditionMerchantOption = typeof ConditionMerchantOptions[number];

export interface ConditionProduct extends Condition {
  merchant: string;
  option: ConditionProductOption;
  timeframe: TimeframeOptionKey;
  timeframeOperator: TimeframeOperatorKey;
  timeframeStart?: number;
  timeframeEnd?: number;
  amount: AmountOptionKey;
  amountCustom?: number;
  amountOperator: FilterOperatorKey;
  count: CountOptionKey;
  countCustom?: number;
  countOperator: FilterOperatorKey;
  productName?: string;
}

export const ConditionProductOptions: ConditionOptionMap = {
  upc: {
    query: 'upc',
    operatorType: 'pattern',
  },
  productCategory: {
    query: 'upccat',
    operatorType: 'pattern2',
  },
  productMerchantName: {
    query: 'merchantname',
    operatorType: 'pattern',
  },
};
export const ConditionProductKeys = Object.keys(ConditionProductOptions);
export type ConditionProductOption = typeof ConditionProductKeys[number];

export interface ConditionTransaction extends Condition {
  option: ConditionTransactionOption;
  timeframe: TimeframeOptionKey;
  timeframeOperator: TimeframeOperatorKey;
  timeframeStart?: number;
  timeframeEnd?: number;
  amount: AmountOptionKey;
  amountCustom?: number;
  amountOperator: FilterOperatorKey;
  count: CountOptionKey;
  countCustom?: number;
  countOperator: FilterOperatorKey;
}

export const ConditionTransactionOptions: ConditionOptionMap = {
  merchantName: {
    query: 'merchant',
    operatorType: 'pattern',
  },
  merchantCategory: 'merchantcat',
};

export const ConditionTransactionKeys = Object.keys(
  ConditionTransactionOptions
);
export type ConditionTransactionOption =
  typeof ConditionTransactionKeys[number];

export interface ConditionSurvey extends Condition {
  option: ConditionSurveyOption;
}

export const ConditionSurveyOptions: ConditionOptionMap = {
  answerid: {
    query: 'answer',
  },
};

export const ConditionSurveyKeys = Object.keys(ConditionSurveyOptions);

export type ConditionSurveyOption = typeof ConditionSurveyKeys[number];

export const ConditionOperators = ['is', 'isNot'] as const;
export type ConditionOperator = typeof ConditionOperators[number];

export const ConditionComparisonOperators = ['>=', '=', '<='];
export type ConditionComparisonOperator =
  typeof ConditionComparisonOperators[number];

export type ConditionPatternOperator = {
  [key: string]: {
    autoComplete: boolean;
  };
};

export const ConditionPatternOperators: ConditionPatternOperator = {
  is: {
    autoComplete: true,
  },
  isNot: {
    autoComplete: true,
  },
  contains: {
    autoComplete: false,
  },
  doesNotContain: {
    autoComplete: false,
  },
};

export const IsAndIsNotConditionPatternOperators: ConditionPatternOperator = {
  is: {
    autoComplete: true,
  },
  isNot: {
    autoComplete: true,
  },
};

export const ConditionPatternKeys = Object.keys(ConditionPatternOperators);
export type ConditionPatternOperatorKey = typeof ConditionPatternKeys[number];
export const IsAndIsNotConditionPatternOperatorsKeys = Object.keys(
  IsAndIsNotConditionPatternOperators
);
export type IsAndIsNotConditionPatternOperatorsOperatorKey =
  typeof IsAndIsNotConditionPatternOperatorsKeys[number];

export interface Dimension {
  id: string;
  sort: number;
  category: DimensionCategory;
  operator: DimensionOperator;
  conditions: DimensionCategoryCondition['id'][];
  timeframe: TimeframeOptionKey;
  timeframeOperator: TimeframeOperatorKey;
  timeframeStart?: number;
  timeframeEnd?: number;
  count: CountOptionKey;
  countCustom?: number;
  countOperator: FilterOperatorKey;
  amount: AmountOptionKey;
  amountCustom?: number;
  amountOperator: FilterOperatorKey;
  audienceSize: number;
  questionCondition?: ConditionSurvey;
}

export type DimensionCategory =
  | 'bankData'
  | 'demographics'
  | 'merchant'
  | 'product'
  | 'transactions'
  | 'survey';

export const DimensionOperators = ['and', 'or'] as const;
export type DimensionOperator = typeof DimensionOperators[number];

export interface DimensionGroup {
  dimension1Id: string;
  dimension2Id: string;
  operator: DimensionOperator;
}

export interface Answer {
  id: string;
  answerText: string;
}

export interface Survey {
  id: string;
  questionText: string;
  answers: Answer[];
}

export function hasTimeframe(
  object: any
): object is ConditionTransaction | ConditionProduct {
  if (
    'timeframe' in object &&
    'timeframeOperator' in object &&
    object.timeframeOperator != ''
  ) {
    if (object.timeframeOperator === 'custom') {
      return 'timeframeEnd' in object && 'timeframeStart' in object;
    } else {
      return object.timeframe !== '';
    }
  }

  return false;
}

export function hasAmount(
  object: any
): object is ConditionTransaction | ConditionProduct {
  return (
    'amount' in object &&
    object.amount != '' &&
    'amountOperator' in object &&
    object.amountOperator != ''
  );
}

export function hasCount(
  object: any
): object is ConditionTransaction | ConditionProduct {
  return (
    'count' in object &&
    object.count != '' &&
    'countOperator' in object &&
    object.countOperator != ''
  );
}

const createNewCondition = () => {
  return {
    id: nanoid(),
    sort: 0,
    description: '',
    operator: '',
    qualifiers: '',
    audienceSize: 0,
  } as Condition;
};

export const createCondition = (dimension: Dimension) => {
  const newCondition = createNewCondition();
  switch (dimension.category) {
    case 'bankData':
      return {
        ...newCondition,
        option: '',
      } as ConditionBanking;
    case 'demographics':
      return {
        ...newCondition,
        option: '',
      } as ConditionDemographic;
    case 'merchant':
      return {
        ...newCondition,
        option: '',
      } as ConditionMerchant;
    case 'product':
      return {
        ...newCondition,
        option: '',
        merchant: '',
        timeframe: '',
        timeframeOperator: '',
        amount: '',
        amountOperator: '',
        count: '',
        countOperator: '',
      } as ConditionProduct;
    case 'transactions':
      return {
        ...newCondition,
        option: '',
        timeframe: '',
        timeframeOperator: '',
        amount: '',
        amountOperator: '',
        count: '',
        countOperator: '',
      } as ConditionTransaction;
    case 'survey':
      return {
        ...newCondition,
        option: '',
      } as ConditionSurvey;
  }
};

// Actions
export interface ToggleDimensionDialogAction {
  dimension?: Dimension;
  open: boolean;
}
export interface ToggleDuplicateAudienceDialogAction {
  open: boolean;
}

export interface ToggleExportDialogAction {
  open: boolean;
}

export interface ToggleAudienceDeleteDialogAction {
  open: boolean;
}

export interface ToggleGroupDeleteDialogAction {
  open: boolean;
}

export interface ToggleGroupNameDialogAction {
  open: boolean;
}

export interface currentAnswersSet {
  [key: string]: boolean;
}

export interface currentCounts {
  [key: string]: number;
}

export interface SurveyMap {
  [key: string]: currentCounts;
}

interface AudienceState {
  currentAudience?: Audience;
  currentConditions: NormalizedObjects<DimensionCategoryCondition>;
  currentDimension?: Dimension;
  currentDimensions: NormalizedObjects<Dimension>;
  currentDimensionGroups: NormalizedObjects<DimensionGroup>;
  currentGroup?: AudienceGroup;
  currentSurvey?: Survey;
  currentAnswers: currentAnswersSet;
  currentResponses: currentCounts;
  currentSurveyResponses?: SurveyMap;
  currentCount: number;
  surveys: NormalizedObjects<Survey>;
  searchResults: Array<string>;
  currentQuery?: string;
  originalAudience?: any;
  entities: {
    audiences: NormalizedObjects<Audience>;
    groups: NormalizedObjects<AudienceGroup>;
    conditions: NormalizedObjects<DimensionCategoryCondition>;
    dimensions: NormalizedObjects<Dimension>;
    dimensionGroups: NormalizedObjects<DimensionGroup>;
    emails: any;
  };
  ui: {
    audienceDeleting: LoadingProps;
    errorFetching: boolean;
    audienceExporting: boolean;
    audienceLoading: LoadingProps;
    audienceSaving: LoadingProps;
    dimensionUpdating: boolean;
    groupsLoading: LoadingProps;
    groupDeleting: LoadingProps;
    groupLoading: LoadingProps;
    groupSaving: LoadingProps;
    emailIsLoading: LoadingProps;
    modeled: boolean;
    notifications: Array<LoadingProps>;
    openAudienceDeleteDialog: boolean;
    openDimensionDialog: boolean;
    openDraftStateDialog: any;
    openDuplicateAudienceDialog: boolean;
    openExportDialog: boolean;
    openGroupDeleteDialog: boolean;
    openGroupNameDialog: boolean;
    states: LoadingPropsObject;
    status: LoadingProps;
    totalAudienceUpdating: boolean;
    surveyLoading: LoadingProps;
  };
}

const initialState: AudienceState = {
  currentConditions: initialNormalizedObject,
  currentDimensions: initialNormalizedObject,
  currentDimensionGroups: initialNormalizedObject,
  searchResults: [],
  surveys: initialNormalizedObject,
  currentAnswers: {},
  currentResponses: {},
  currentSurveyResponses: {},
  currentCount: 0,
  entities: {
    audiences: initialNormalizedObject,
    groups: initialNormalizedObject,
    conditions: initialNormalizedObject,
    dimensions: initialNormalizedObject,
    dimensionGroups: initialNormalizedObject,
    emails: [],
  },
  ui: {
    audienceDeleting: { state: 'idle' },
    errorFetching: false,
    audienceExporting: false,
    audienceLoading: { state: 'idle' },
    audienceSaving: { state: 'idle' },
    dimensionUpdating: false,
    groupsLoading: { state: 'idle' },
    groupDeleting: { state: 'idle' },
    groupLoading: { state: 'idle' },
    groupSaving: { state: 'idle' },
    emailIsLoading: { state: 'idle' },
    modeled: true,
    notifications: [],
    openAudienceDeleteDialog: false,
    openDimensionDialog: false,
    openDraftStateDialog: { open: false },
    openDuplicateAudienceDialog: false,
    openExportDialog: false,
    openGroupDeleteDialog: false,
    openGroupNameDialog: false,
    states: {},
    status: { state: 'idle' },
    totalAudienceUpdating: false,
    surveyLoading: { state: 'idle' },
  },
};

export const audienceSlice = createSlice({
  name: 'audience',
  initialState,
  reducers: {
    addAudience: (state, action: PayloadAction<Audience>) => {
      state.currentAudience = action.payload;
      state.currentDimensions = {
        byId: {},
        allIds: [],
      };
      state.currentConditions = {
        byId: {},
        allIds: [],
      };
      state.currentDimensionGroups = {
        byId: {},
        allIds: [],
      };
    },
    addCondition: (state) => {
      const dimension = state.currentDimension;
      if (!dimension) {
        return;
      }

      const incompleteCondition = dimension.conditions.find((id) => {
        const condition = state.currentConditions.byId[id];
        if (condition) {
          return (
            condition.option === '' ||
            condition.operator === '' ||
            condition.qualifiers === ''
          );
        } else {
          return false;
        }
      });

      if (!incompleteCondition) {
        const newCondtion = createCondition(dimension);
        state.currentConditions.allIds.push(newCondtion.id);
        state.currentConditions.byId[newCondtion.id] = newCondtion;
        state.currentDimension = dimension;
        state.currentDimension.conditions.push(newCondtion.id);
      }
    },
    applyDimension: (state, action: PayloadAction<Dimension>) => {
      if (!state.currentAudience) {
        return;
      }

      const dimension = _.cloneDeep(action.payload);

      if (dimension.category === 'survey') {
        if (
          !dimension.conditions.length ||
          // (
          dimension.conditions.length
          // &&
          // state.currentConditions.byId[state.currentDimension.conditions[0]]
          //   .option !== 'question')
        ) {
          let index = 1;
          dimension.conditions.forEach((conditionid) => {
            if (state.currentConditions.byId[conditionid].option === 'answer') {
              state.currentConditions.byId[conditionid].sort = index;
              index++;
            }
          });
          const questionCondition: ConditionSurvey = createCondition(dimension);
          if (state.currentSurvey) {
            questionCondition.qualifiers = state.currentSurvey?.id;
          }
          questionCondition.option = 'question';
          questionCondition.operator = 'is';
          questionCondition.sort = 0;
          dimension.conditions = [
            questionCondition.id,
            ...dimension.conditions,
          ];
          state.currentConditions.allIds.push(questionCondition.id);
          state.currentConditions.byId[questionCondition.id] =
            questionCondition;
        }
      }

      const dimensionIds = state.currentAudience.dimensions;
      state.currentAudience.dimensions = _.union(dimensionIds, [dimension.id]);
      state.currentDimensions.byId[dimension.id] = dimension;

      const index = state.currentDimensions.allIds.findIndex(
        (id) => id === dimension.id
      );
      if (index === -1) {
        state.currentDimensions.allIds.push(dimension.id);
      }

      // create new dimension group if it doesn't exist
      const newIndex = state.currentDimensions.allIds.indexOf(dimension.id);
      const prevId = state.currentDimensions.allIds[newIndex - 1];
      const dimensionGroup = state.currentDimensionGroups.byId[prevId];

      if (prevId && dimensionGroup === undefined) {
        const newDimenstionGroup: DimensionGroup = {
          dimension1Id: prevId,
          dimension2Id: dimension.id,
          operator: 'and',
        };
        state.currentDimensionGroups.allIds.push(prevId);
        state.currentDimensionGroups.byId[prevId] = newDimenstionGroup;
        state.currentAudience.dimensionGroups = _.union(
          state.currentAudience.dimensionGroups,
          [prevId]
        );
      }
    },
    addGroup: (state, action: PayloadAction<AudienceGroup>) => {
      const group = action.payload;
      state.entities.groups.byId[group.id] = group;
      state.entities.groups.allIds.push(group.id);
    },
    clearAudiences: (state) => {
      state.entities.audiences = initialNormalizedObject;
    },
    clearCurrentAudience: (state) => {
      state.currentAudience = undefined;
      state.currentDimensions = {
        byId: {},
        allIds: [],
      };
      state.currentConditions = {
        byId: {},
        allIds: [],
      };
      state.currentDimensionGroups = {
        byId: {},
        allIds: [],
      };
    },
    createAudience: () => {
      //skeleton
    },
    createGroup: () => {
      // Skeleton to get helpers to build actions
      // Refer saga.ts
    },
    deleteCondition: (
      state,
      action: PayloadAction<DimensionCategoryCondition>
    ) => {
      const condition = action.payload;
      const conditionsAllIds = [...state.currentConditions.allIds];
      const conditionsById = { ...state.currentConditions.byId };

      const index = conditionsAllIds.indexOf(condition.id);
      if (index > -1) {
        conditionsAllIds.splice(index, 1);
      }
      delete conditionsById[condition.id];

      state.currentConditions.allIds = conditionsAllIds;
      state.currentConditions.byId = conditionsById;

      const currentDimension = state.currentDimension;
      if (currentDimension) {
        currentDimension.conditions = currentDimension.conditions.filter(
          (id) => id !== condition.id
        );
        state.currentDimension = currentDimension;
      }
    },
    deleteDimension: (state, action: PayloadAction<Dimension>) => {
      if (!state.currentAudience) {
        return;
      }

      const dimension = action.payload;
      const dimensionIds = state.currentAudience.dimensions;

      state.currentAudience.dimensions = dimensionIds.filter(
        (id) => id != dimension.id
      );
      state.currentDimensions.allIds = state.currentDimensions.allIds.filter(
        (id) => id != dimension.id
      );

      const dimensionById = { ...state.currentDimensions.byId };
      delete dimensionById[dimension.id];
      state.currentDimensions.byId = dimensionById;

      // Remove all conditions
      const conditionsAllIds = [...state.currentConditions.allIds];
      const conditionsById = { ...state.currentConditions.byId };
      dimension.conditions.forEach((id) => {
        const index = conditionsAllIds.indexOf(id);
        if (index > -1) {
          conditionsAllIds.splice(index, 1);
        }
        delete conditionsById[id];
      });
      state.currentConditions.allIds = conditionsAllIds;
      state.currentConditions.byId = conditionsById;

      // Remove dimension group
      const dimensionGroupsAllIds = [...state.currentDimensionGroups.allIds];
      const dimensionGroupsById = { ...state.currentDimensionGroups.byId };
      const dimensionIndex = dimensionGroupsAllIds.indexOf(dimension.id);
      if (dimensionIndex > -1) {
        dimensionGroupsAllIds.splice(dimensionIndex, 1);
      }

      if (dimensionGroupsById[dimension.id]) {
        delete dimensionGroupsById[dimension.id];
      }
      Object.values(dimensionGroupsById).forEach((dimensionGroup) => {
        if (dimensionGroup.dimension2Id === dimension.id) {
          delete dimensionGroupsById[dimensionGroup.dimension1Id];

          const dimensionGroupIndex = dimensionGroupsAllIds.indexOf(
            dimensionGroup.dimension1Id
          );
          if (dimensionGroupIndex > -1) {
            dimensionGroupsAllIds.splice(dimensionGroupIndex, 1);
          }
        }
      });

      state.currentDimensionGroups.allIds = dimensionGroupsAllIds;
      state.currentDimensionGroups.byId = dimensionGroupsById;

      // Add dimension group between dimensions that are left
      state.currentDimensions.allIds.forEach((id, index) => {
        const prevId = state.currentDimensions.allIds[index - 1];
        const dimensionGroup = state.currentDimensionGroups.byId[prevId];

        if (prevId && dimensionGroup === undefined) {
          const newDimenstionGroup: DimensionGroup = {
            dimension1Id: prevId,
            dimension2Id: id,
            operator: 'and',
          };
          state.currentDimensionGroups.allIds.push(prevId);
          state.currentDimensionGroups.byId[prevId] = newDimenstionGroup;
        }
      });
    },
    deleteAudience: () => {
      //
    },
    deleteGroup: () => {
      // Skeleton to get helpers to build actions
      // Refer saga.ts
    },
    duplicateAudience: () => {
      // skeleton to build actions
    },
    exportAudience: () => {
      // Skeleton to get helpers to build actions
      // Refer saga.ts
    },
    fetchGroup: () => {
      // Skeleton to get helpers to build actions
      // Refer saga.ts
    },
    fetchGroups: () => {
      // Skeleton to get helpers to build actions
      // Refer saga.ts
    },
    fetchEmails: () => {
      // Skeleton to get helpers to build actions
      // Refer saga.ts
    },
    addNotification: (state, action: PayloadAction<LoadingProps>) => {
      state.ui.notifications.push(action.payload);
    },
    query: () => {
      // Skeleton to get helpers to build actions
      // Refer saga.ts
    },
    querySurveys: () => {
      // Skeleton to get helpers to build actions
      // Refer saga.ts
    },
    queryTotalAudienceSize: () => {
      // Skeleton to get helpers to build actions
      // Refer saga.ts
    },
    removeAudience: (state, action: PayloadAction<Audience>) => {
      const audienceId = action.payload.id;
      if (state.currentGroup)
        state.currentGroup.audiences = state.currentGroup.audiences.filter(
          (id) => id !== audienceId
        );
      delete state.entities.audiences.byId[audienceId];
      state.entities.audiences.allIds = state.entities.audiences.allIds.filter(
        (id) => id !== audienceId
      );
    },
    removeDimensionsAndConditions: (state, action: PayloadAction<Audience>) => {
      const audience = action.payload;
      const dimensionIds = audience.dimensions;
      const dimensions = [];
      let conditionIds: Array<string> = [];
      for (let i = 0; i < dimensionIds.length; i++) {
        const dimensionId = dimensionIds[i];
        const dimension = current(state.entities.dimensions.byId[dimensionId]);
        if (dimension) {
          dimensions.push(dimension);
          const dimensionConditionIds = dimension.conditions || [];
          conditionIds = conditionIds.concat(dimensionConditionIds);
          delete state.entities.dimensions.byId[dimensionId];
        }
      }
      state.entities.dimensions.allIds =
        state.entities.dimensions.allIds.filter(
          (id) => !dimensionIds.find((elem) => elem == id)
        );
      state.entities.conditions.allIds =
        state.entities.conditions.allIds.filter(
          (id) => !conditionIds.find((elem) => elem == id)
        );
      for (let i = 0; i < conditionIds.length; i++) {
        const conditionId = conditionIds[i];
        const condition = current(state.entities.conditions.byId[conditionId]);
        if (condition) {
          delete state.entities.conditions.byId[conditionId];
        }
      }
    },
    removeDimensionGroups: (state, action: PayloadAction<Audience>) => {
      const audience = action.payload;
      const byIds = _.clone(current(state.entities.dimensionGroups.byId));
      const newById = {};
      let newAllIds = [];
      for (const key in byIds) {
        const dimensionGroup = byIds[key];
        const dimension1Id = dimensionGroup.dimension1Id;
        const dimension2Id = dimensionGroup.dimension2Id;
        if (dimensionGroup.audienceId !== audience.id) {
          newById[key] = dimensionGroup;
          newAllIds = _.union(newAllIds, [dimension1Id, dimension2Id]);
        }
      }
      state.entities.dimensionGroups.allIds = newAllIds;
      state.entities.dimensionGroups.byId = newById;
    },
    removeGroup: (state, action: PayloadAction<AudienceGroup>) => {
      const group = state.entities.groups.byId[action.payload.id];

      let groupsAllIds = [...state.entities.groups.allIds];
      const groupsById = { ...state.entities.groups.byId };
      let audiencesAllIds = [...state.entities.audiences.allIds];
      const audiencesById = { ...state.entities.audiences.byId };
      let dimensionsAllIds = [...state.entities.dimensions.allIds];
      const dimensionsById = { ...state.entities.dimensions.byId };
      let conditionsAllIds = [...state.entities.conditions.allIds];
      const conditionsById = { ...state.entities.conditions.byId };
      let dimensionGroupsAllIds = [...state.entities.dimensionGroups.allIds];
      const dimensionGroupsById = { ...state.entities.dimensionGroups.byId };

      // Remove group
      groupsAllIds = groupsAllIds.filter((el) => el !== group.id);
      delete groupsById[group.id];

      audiencesAllIds = audiencesAllIds.filter(
        (el) => !group.audiences.includes(el)
      );
      group.audiences.forEach((audienceId) => {
        const audience = state.entities.audiences.byId[audienceId];
        delete audiencesById[audienceId];

        // Remove all dimensions
        dimensionsAllIds = dimensionsAllIds.filter(
          (el) => !audience.dimensions.includes(el)
        );
        audience.dimensions.forEach((dimensionId) => {
          const dimension = state.entities.dimensions.byId[dimensionId];
          delete dimensionsById[dimensionId];

          // Remove all conditions
          conditionsAllIds = conditionsAllIds.filter(
            (el) => !dimension.conditions.includes(el)
          );
          dimension.conditions.forEach((conditionId) => {
            delete conditionsById[conditionId];
          });
        });

        // Remove all dimension groups
        dimensionGroupsAllIds = dimensionGroupsAllIds.filter(
          (el) => !audience.dimensionGroups.includes(el)
        );
        audience.dimensionGroups.forEach((dimensionGroupId) => {
          delete dimensionGroupsById[dimensionGroupId];
        });
      });

      // Reset state.entities
      const entities = {
        groups: {
          allIds: groupsAllIds,
          byId: groupsById,
        },
        audiences: {
          allIds: audiencesAllIds,
          byId: audiencesById,
        },
        dimensions: {
          allIds: dimensionsAllIds,
          byId: dimensionsById,
        },
        conditions: {
          allIds: conditionsAllIds,
          byId: conditionsById,
        },
        dimensionGroups: {
          allIds: dimensionGroupsAllIds,
          byId: dimensionGroupsById,
        },
      };

      state.entities = entities;

      if (state.currentGroup && state.currentGroup.id === group.id) {
        state.currentGroup = undefined;
      }
    },
    removeNotification: (state) => {
      state.ui.notifications = state.ui.notifications.slice(1);
    },
    removeStates: (state, action) => {
      state.ui.states = _.omit(state.ui.states, action.payload.id);
    },
    saveAudience: () => {
      // skeleton
    },
    saveGroup: () => {
      // Skeleton to get helpers to build actions
      // Refer saga.ts
    },
    setGroupDeletingState: (state, action: PayloadAction<LoadingProps>) => {
      state.ui.groupDeleting = action.payload;
    },
    setGroupLoadingState: (state, action: PayloadAction<LoadingProps>) => {
      state.ui.groupLoading = action.payload;
    },
    setGroupsLoadingState: (state, action: PayloadAction<LoadingProps>) => {
      state.ui.groupsLoading = action.payload;
    },
    setGroupSavingState: (state, action: PayloadAction<LoadingProps>) => {
      state.ui.groupSaving = action.payload;
    },
    setEmailIsLoadingState: (state, action: PayloadAction<LoadingProps>) => {
      state.ui.emailIsLoading = action.payload;
    },
    setModeled: (state, action: PayloadAction<boolean>) => {
      state.ui.modeled = action.payload;
    },
    setSurveyLoading: (state, action: PayloadAction<LoadingProps>) => {
      state.ui.surveyLoading = action.payload;
    },
    setUIState: (state, action) => {
      const { value, uiPropertyName } = action.payload;
      state.ui[uiPropertyName] = value;
    },
    toggleDimensionDialog: (
      state,
      action: PayloadAction<ToggleDimensionDialogAction>
    ) => {
      //onclose action
      if (!action.payload.open) {
        //survey dimension formatting
        if (
          state.currentDimension &&
          state.currentDimension.category === 'survey' &&
          state.currentDimensions.byId[state.currentDimension.id]
        ) {
          let index = 1;
          state.currentDimension.conditions.forEach((conditionid) => {
            if (state.currentConditions.byId[conditionid].option === 'answer') {
              state.currentConditions.byId[conditionid].sort = index;
              index++;
            }
          });
          if (
            !state.currentDimension.conditions.length ||
            (state.currentDimension.conditions.length &&
              state.currentConditions.byId[
                state.currentDimension.conditions[0]
              ] &&
              state.currentConditions.byId[state.currentDimension.conditions[0]]
                .option !== 'question')
          ) {
            const questionCondition: ConditionSurvey = createCondition(
              state.currentDimension
            );
            if (state.currentSurvey) {
              questionCondition.qualifiers = state.currentSurvey?.id;
            }
            questionCondition.option = 'question';
            questionCondition.operator = 'is';
            questionCondition.sort = 0;
            state.currentDimension.conditions = [
              questionCondition.id,
              ...state.currentDimension.conditions,
            ];
            state.currentConditions.allIds.push(questionCondition.id);
            state.currentConditions.byId[questionCondition.id] =
              questionCondition;
          }
          if (state.currentDimensions.byId[state.currentDimension.id]) {
            state.currentDimensions.byId[state.currentDimension.id].conditions =
              state.currentDimension.conditions;
          } else {
            state.currentDimensions.allIds.push(state.currentDimension.id);
            state.currentDimensions.byId[state.currentDimension.id].conditions =
              state.currentDimension.conditions;
          }
        }
      } else if (action.payload.dimension && action.payload.open) {
        //reset currentanswers and surveys)
        let existsEmptyCondition = false;
        const dimensionConditionIds = _.clone(
          action.payload.dimension.conditions
        );
        for (const conditionId of dimensionConditionIds) {
          const condition = current(state.currentConditions.byId)[conditionId];
          if (
            (condition && !condition.qualifiers.length) ||
            action.payload.dimension.category === 'survey'
          ) {
            existsEmptyCondition = true;
          }
        }
        if (existsEmptyCondition) {
          // if (action.payload.dimension.category === 'survey') {

          // }
          state.currentDimension = action.payload.dimension;
        } else {
          // setting the dimension to whatever we clicked on
          const dimension = _.clone(action.payload.dimension);

          // creating a new condition
          const newCondtion = createCondition(action.payload.dimension);
          // putting a condition into currentdimension

          dimension.conditions = dimension.conditions.concat(newCondtion.id);
          // adding the new conditionid into the currentConditions.allIds
          state.currentConditions.allIds =
            state.currentConditions.allIds.concat(newCondtion.id);
          // setting the currentConditions.byId to the newcondition
          state.currentConditions.byId[newCondtion.id] = newCondtion;
          // setting the current dimension to the dimension
          state.currentDimension = dimension;
        }
      } else {
        state.currentDimension = undefined;
      }
      state.ui.openDimensionDialog = action.payload.open;
      state.currentAnswers = {};
      state.currentSurvey = undefined;
    },
    resetCurrentConditions: (state) => {
      state.currentAnswers = {};
      state.currentSurvey = undefined;
      if (state.currentDimension) {
        const conditionsToRemoveSet = new Set(
          state.currentDimension.conditions
        );
        //reset current conditions
        state.currentConditions.allIds = state.currentConditions.allIds.filter(
          (conditionid) => !conditionsToRemoveSet.has(conditionid)
        );
        state.currentDimension.conditions.forEach((conditionid) => {
          delete state.currentConditions.byId[conditionid];
        });
        state.currentDimension.conditions = [];
      }
    },
    toggleDraftStateDialog: (state, action) => {
      state.ui.openDraftStateDialog = action.payload;
    },
    toggleDuplicateAudienceDialog: (
      state,
      action: PayloadAction<ToggleDuplicateAudienceDialogAction>
    ) => {
      state.ui.openDuplicateAudienceDialog = action.payload.open;
    },
    toggleExportDialog: (
      state,
      action: PayloadAction<ToggleExportDialogAction>
    ) => {
      state.ui.openExportDialog = action.payload.open;
    },
    toggleAudienceDeleteDialog: (
      state,
      action: PayloadAction<ToggleAudienceDeleteDialogAction>
    ) => {
      state.ui.openAudienceDeleteDialog = action.payload.open;
      if (action.payload.open) {
        state.ui.audienceDeleting = { state: 'idle' };
      }
    },
    toggleAudienceExporting: (state, action) => {
      state.ui.audienceExporting = action.payload;
    },
    toggleGroupDeleteDialog: (
      state,
      action: PayloadAction<ToggleGroupDeleteDialogAction>
    ) => {
      state.ui.openGroupDeleteDialog = action.payload.open;
      if (action.payload.open) {
        state.ui.groupDeleting = { state: 'idle' };
      }
    },
    toggleGroupNameDialog: (
      state,
      action: PayloadAction<ToggleGroupNameDialogAction>
    ) => {
      state.ui.openGroupNameDialog = action.payload.open;
      if (action.payload.open) {
        state.ui.groupSaving = { state: 'idle' };
      }
    },
    updateAudiences: (state, action: PayloadAction<Audience[]>) => {
      const audiences = action.payload;
      const audienceIds = audiences.map((audience) => {
        state.entities.audiences.byId[audience.id] = audience;
        return audience.id;
      });

      // state.entities.audiences.allIds = _.union(
      //   state.entities.audiences.allIds,
      //   audienceIds
      // );
      state.entities.audiences.allIds = audienceIds;
    },
    updateCondition: (
      state,
      action: PayloadAction<DimensionCategoryCondition>
    ) => {
      if (!state.currentDimension) {
        return;
      }

      const condition = action.payload;

      if (hasTimeframe(condition)) {
        condition.timeframe = state.currentDimension.timeframe;
        condition.timeframeEnd = state.currentDimension.timeframeEnd;
        condition.timeframeOperator = state.currentDimension.timeframeOperator;
        condition.timeframeStart = state.currentDimension.timeframeStart;
      }

      if (hasAmount(condition)) {
        condition.amount = state.currentDimension.amount;
        condition.amountCustom = state.currentDimension.amountCustom;
        condition.amountOperator = state.currentDimension.amountOperator;
      }

      if (hasCount(condition)) {
        condition.count = state.currentDimension.count;
        condition.countCustom = state.currentDimension.countCustom;
        condition.countOperator = state.currentDimension.countOperator;
      }

      if (!state.currentConditions.byId[condition.id]) {
        state.currentConditions.allIds.push(condition.id);
        state.currentDimension.conditions.push(condition.id);
      }

      state.currentConditions.byId[condition.id] = condition;

      return state;
    },
    updateConditions: (
      state,
      action: PayloadAction<DimensionCategoryCondition[]>
    ) => {
      for (const condition of action.payload) {
        state.currentConditions.byId[condition.id] = condition;
      }
    },
    updateCurrentGroup: (state, action: PayloadAction<AudienceGroup>) => {
      state.currentGroup = action.payload;
    },
    updateCurrentGroupAudiences: (state, action: PayloadAction<Audience>) => {
      if (state.currentGroup) {
        state.currentGroup.audiences = _.union(state.currentGroup.audiences, [
          action.payload.id,
        ]);
      }
    },
    updateDimension: (state, action: PayloadAction<Dimension>) => {
      const dimension = action.payload;
      const currentDimension = state.currentDimension;

      if (currentDimension && currentDimension.id === dimension.id) {
        state.currentDimension = dimension;
      }

      if (state.currentDimensions.byId[dimension.id]) {
        state.currentDimensions.byId[dimension.id] = dimension;
      }
    },
    updateDimensionGroup: (state, action: PayloadAction<DimensionGroup>) => {
      const dimensionGroup = action.payload;
      state.currentDimensionGroups.allIds = _.union(
        state.currentDimensionGroups.allIds,
        [dimensionGroup.dimension1Id]
      );
      state.currentDimensionGroups.byId[dimensionGroup.dimension1Id] =
        dimensionGroup;
    },
    updateGroup: (state, action: PayloadAction<AudienceGroup>) => {
      const group = action.payload;
      state.entities.groups.byId[group.id] = group;
    },
    updateGroups: (state, action: PayloadAction<AudienceGroup[]>) => {
      const groups = action.payload;
      groups.forEach((group) => {
        state.entities.groups.byId[group.id] = group;
      });
      state.entities.groups.allIds = groups.map((group) => group.id);
    },
    updateEmails: (state, action) => {
      state.entities.emails = action.payload;
    },
    updateStates: (state, action) => {
      state.ui.states = _.assign(state.ui.states, {
        [action.payload.id]: action.payload,
      });
    },
    updateTimeframe: (state, action: PayloadAction<TimeframeOptionKey>) => {
      if (!state.currentDimension) {
        return;
      }

      const timeframe = action.payload;
      const dimension = state.currentDimension;
      dimension.conditions.forEach((id) => {
        const condition = {
          ...(state.currentConditions.byId[id] as DimensionCategoryCondition),
          timeframe: timeframe,
        };
        state.currentConditions.byId[id] = condition;
      });

      dimension.timeframe = timeframe;

      state.currentDimension = dimension;
      if (state.currentDimensions.byId[dimension.id]) {
        state.currentDimensions.byId[dimension.id] = dimension;
      }
    },
    updateTimeframeEnd: (state, action: PayloadAction<number | undefined>) => {
      if (!state.currentDimension) {
        return;
      }

      const endDate = +action.payload;
      const dimension = state.currentDimension;
      dimension.conditions.forEach((id) => {
        const condition = {
          ...(state.currentConditions.byId[id] as DimensionCategoryCondition),
          timeframeEnd: endDate,
        };
        state.currentConditions.byId[id] = condition;
      });

      dimension.timeframeEnd = endDate;

      state.currentDimension = dimension;
      if (state.currentDimensions.byId[dimension.id]) {
        state.currentDimensions.byId[dimension.id] = dimension;
      }
    },
    updateTimeframeOperator: (
      state,
      action: PayloadAction<TimeframeOperatorKey>
    ) => {
      if (!state.currentDimension) {
        return;
      }

      const timeframeOperator = action.payload;
      const dimension = state.currentDimension;
      dimension.conditions.forEach((id) => {
        const condition = state.currentConditions.byId[id] as
          | ConditionProduct
          | ConditionTransaction;

        const updatedCondition = {
          ...condition,
          timeframe: timeframeOperator === '' ? '' : condition.timeframe,
          timeframeOperator: timeframeOperator,
        };
        state.currentConditions.byId[id] = updatedCondition;
      });

      if (timeframeOperator === '') {
        dimension.timeframe = '';
      }
      dimension.timeframeOperator = timeframeOperator;

      state.currentDimension = dimension;
      if (state.currentDimensions.byId[dimension.id]) {
        state.currentDimensions.byId[dimension.id] = dimension;
      }
    },
    updateTimeframeStart: (
      state,
      action: PayloadAction<number | undefined>
    ) => {
      if (!state.currentDimension) {
        return;
      }

      const startDate = action.payload;
      const dimension = state.currentDimension;
      dimension.conditions.forEach((id) => {
        const condition = {
          ...(state.currentConditions.byId[id] as DimensionCategoryCondition),
          timeframeStart: startDate,
        };
        state.currentConditions.byId[id] = condition;
      });

      dimension.timeframeStart = startDate;

      state.currentDimension = dimension;
      if (state.currentDimensions.byId[dimension.id]) {
        state.currentDimensions.byId[dimension.id] = dimension;
      }
    },
    updateAmount: (state, action: PayloadAction<AmountOptionKey>) => {
      if (!state.currentDimension) {
        return;
      }

      const amount = action.payload;
      const dimension = state.currentDimension;
      dimension.conditions.forEach((id) => {
        const condition = {
          ...(state.currentConditions.byId[id] as DimensionCategoryCondition),
          amount: amount,
        };
        state.currentConditions.byId[id] = condition;
      });

      dimension.amount = amount;

      state.currentDimension = dimension;
      if (state.currentDimensions.byId[dimension.id]) {
        state.currentDimensions.byId[dimension.id] = dimension;
      }
    },
    updateAmountCustom: (state, action: PayloadAction<number | undefined>) => {
      if (!state.currentDimension) {
        return;
      }

      const amountCustom = action.payload;
      const dimension = state.currentDimension;
      dimension.conditions.forEach((id) => {
        const condition = {
          ...(state.currentConditions.byId[id] as DimensionCategoryCondition),
          amountCustom: amountCustom,
        };
        state.currentConditions.byId[id] = condition;
      });

      dimension.amountCustom = amountCustom;

      state.currentDimension = dimension;
      if (state.currentDimensions.byId[dimension.id]) {
        state.currentDimensions.byId[dimension.id] = dimension;
      }
    },
    updateAmountOperator: (state, action: PayloadAction<FilterOperatorKey>) => {
      if (!state.currentDimension) {
        return;
      }

      const amountOperator = action.payload;
      const dimension = state.currentDimension;
      dimension.conditions.forEach((id) => {
        const condition = state.currentConditions.byId[id] as
          | ConditionProduct
          | ConditionTransaction;
        const updatedCondition = {
          ...condition,
          amount: amountOperator === '' ? '' : condition.amount,
          amountOperator: amountOperator,
        };
        state.currentConditions.byId[id] = updatedCondition;
      });

      if (amountOperator === '') {
        dimension.amount = '';
      }
      dimension.amountOperator = amountOperator;

      state.currentDimension = dimension;
      if (state.currentDimensions.byId[dimension.id]) {
        state.currentDimensions.byId[dimension.id] = dimension;
      }
    },
    updateCount: (state, action: PayloadAction<CountOptionKey>) => {
      if (!state.currentDimension) {
        return;
      }

      const count = action.payload;
      const dimension = state.currentDimension;
      dimension.conditions.forEach((id) => {
        const condition = {
          ...(state.currentConditions.byId[id] as DimensionCategoryCondition),
          count: count,
        };
        state.currentConditions.byId[id] = condition;
      });

      dimension.count = count;

      state.currentDimension = dimension;
      if (state.currentDimensions.byId[dimension.id]) {
        state.currentDimensions.byId[dimension.id] = dimension;
      }
    },
    updateCountCustom: (state, action: PayloadAction<number | undefined>) => {
      if (!state.currentDimension) {
        return;
      }

      const countCustom = action.payload;
      const dimension = state.currentDimension;
      dimension.conditions.forEach((id) => {
        const condition = {
          ...(state.currentConditions.byId[id] as DimensionCategoryCondition),
          countCustom: countCustom,
        };
        state.currentConditions.byId[id] = condition;
      });

      dimension.countCustom = countCustom;

      state.currentDimension = dimension;
      if (state.currentDimensions.byId[dimension.id]) {
        state.currentDimensions.byId[dimension.id] = dimension;
      }
    },
    updateCountOperator: (state, action: PayloadAction<FilterOperatorKey>) => {
      if (!state.currentDimension) {
        return;
      }

      const countOperator = action.payload;
      const dimension = state.currentDimension;
      dimension.conditions.forEach((id) => {
        const condition = state.currentConditions.byId[id] as
          | ConditionProduct
          | ConditionTransaction;
        const updatedCondition = {
          ...condition,
          count: countOperator === '' ? '' : condition.count,
          countOperator: countOperator,
        };
        state.currentConditions.byId[id] = updatedCondition;
      });

      if (countOperator === '') {
        dimension.count = '';
      }
      dimension.countOperator = countOperator;

      state.currentDimension = dimension;
      if (state.currentDimensions.byId[dimension.id]) {
        state.currentDimensions.byId[dimension.id] = dimension;
      }
    },
    updateCurrentSurvey: (state, action: PayloadAction<Survey>) => {
      state.currentSurvey = action.payload;
    },
    resetCurrentAnswers: (state) => {
      state.currentAnswers = {};
    },
    populateCurrentAnswers: (state, action: PayloadAction<string>) => {
      if (state.currentAnswers[action.payload]) {
        state.currentAnswers[action.payload] = false;
      } else {
        state.currentAnswers[action.payload] = true;
      }
    },
    updateCurrentAnswers: (state, action: PayloadAction<string>) => {
      if (state.currentAnswers[action.payload]) {
        state.currentAnswers[action.payload] = false;
        if (state.currentDimension) {
          const answerId = action.payload;
          let answerConditionToRemove: string | undefined;
          state.currentDimension.conditions.forEach((conditionid) => {
            if (
              state.currentConditions.byId[conditionid].qualifiers === answerId
            ) {
              answerConditionToRemove = conditionid;
            }
          });
          if (answerConditionToRemove) {
            state.currentDimension.conditions =
              state.currentDimension.conditions.filter(
                (conditionid) => answerConditionToRemove !== conditionid
              );
            state.currentConditions.allIds =
              state.currentConditions.allIds.filter(
                (conditionid) => answerConditionToRemove !== conditionid
              );
            delete state.currentConditions.byId[answerConditionToRemove];
          }
        }
      } else {
        //add answer condition to the survey dimension
        if (state.currentDimension) {
          const newAnswerCondition: ConditionSurvey = createCondition(
            state.currentDimension
          );
          newAnswerCondition.qualifiers = action.payload;
          newAnswerCondition.option = 'answer';
          newAnswerCondition.operator = 'is';
          newAnswerCondition.audienceSize =
            Math.floor(state.currentResponses[action.payload]) || 0;
          state.currentDimension.conditions.push(newAnswerCondition.id);
          state.currentConditions.allIds.push(newAnswerCondition.id);
          state.currentConditions.byId[newAnswerCondition.id] =
            newAnswerCondition;
          state.currentAnswers[action.payload] = true;
        }
      }
    },
    updateCurrentResponses: (state, action: PayloadAction<currentCounts>) => {
      state.currentResponses = action.payload;
    },
    updateCurrentCount: (state, action: PayloadAction<number>) => {
      state.currentCount = action.payload;
    },
    updateSurveyMap: (state, action: PayloadAction<any>) => {
      state.currentSurveyResponses[action.payload.questionid] =
        action.payload.responses;
    },
    updatingDimension: (state, action: PayloadAction<boolean>) => {
      state.ui.dimensionUpdating = action.payload;
    },
    updatingTotalAudience: (state, action: PayloadAction<boolean>) => {
      state.ui.totalAudienceUpdating = action.payload;
    },
    fillUPCSearchResults: (state, action: PayloadAction<Array<StringMap>>) => {
      state.searchResults = action.payload;
    },
    loadOriginalAudience: (state, action) => {
      state.originalAudience = action.payload;
    },
    clearSearchResults: (state) => {
      state.searchResults = [];
    },
    updateCurrentAudience: (state, action: PayloadAction<Audience>) => {
      state.currentAudience = action.payload;
    },
    updateCurrentDimensions: (
      state,
      action: PayloadAction<Array<Dimension>>
    ) => {
      state.currentDimensions = getNormalizedObject(action.payload, 'id');
    },
    updateCurrentDimensionGroups: (
      state,
      action: PayloadAction<Array<DimensionGroup>>
    ) => {
      state.currentDimensionGroups = getNormalizedObject(
        action.payload,
        'dimension1Id'
      );
    },
    updateCurrentConditions: (
      state,
      action: PayloadAction<Array<DimensionCategoryCondition>>
    ) => {
      state.currentConditions = getNormalizedObject(action.payload, 'id');
    },
    updateAudiencesEntities: (state, action: PayloadAction<Audience>) => {
      state.entities.audiences = getNormalizedObject(
        [action.payload],
        'id',
        state.entities.audiences
      );
    },
    updateDimensionsEntities: (
      state,
      action: PayloadAction<Array<Dimension>>
    ) => {
      state.entities.dimensions = getNormalizedObject(
        action.payload,
        'id',
        state.entities.dimensions
      );
    },
    updateDimensionGroupsEntities: (
      state,
      action: PayloadAction<Array<DimensionGroup>>
    ) => {
      state.entities.dimensionGroups = getNormalizedObject(
        action.payload,
        'dimension1Id',
        state.entities.dimensionGroups
      );
      state.entities.dimensionGroups = getNormalizedObject(
        action.payload,
        'dimension2Id',
        state.entities.dimensionGroups
      );
    },
    updateConditionsEntities: (
      state,
      action: PayloadAction<Array<DimensionCategoryCondition>>
    ) => {
      state.entities.conditions = getNormalizedObject(
        action.payload,
        'id',
        state.entities.conditions
      );
    },
    updateCurrentDimension: (state, action: PayloadAction<Dimension>) => {
      state.currentDimension = action.payload;
    },
    updateSurvey: (state, action: PayloadAction<Survey>) => {
      state.currentSurvey = action.payload;
    },
    updateSurveys: (state, action: PayloadAction<Survey[]>) => {
      state.surveys = getNormalizedObject(action.payload, 'id', state.surveys);
    },
    fetchAudience: (state, action: PayloadAction<Audience>) => {
      ///
    },
    searchAudiences: () => {
      // Skeleton
    },
    searchQuery: () => {
      // shell
    },
    setAudienceLoadingState: (state, action: PayloadAction<LoadingProps>) => {
      state.ui.audienceLoading = action.payload;
    },
  },
});

export const {
  addAudience,
  addNotification,
  addCondition,
  applyDimension,
  addGroup,
  clearAudiences,
  clearCurrentAudience,
  clearSearchResults,
  createAudience,
  createGroup,
  deleteCondition,
  deleteDimension,
  deleteAudience,
  deleteGroup,
  duplicateAudience,
  exportAudience,
  fetchAudience,
  fetchGroup,
  fetchGroups,
  fetchEmails,
  fillUPCSearchResults,
  loadOriginalAudience,
  saveGroup,
  saveAudience,
  searchAudiences,
  setAudienceLoadingState,
  setGroupDeletingState,
  setGroupLoadingState,
  setGroupsLoadingState,
  setGroupSavingState,
  setEmailIsLoadingState,
  setModeled,
  setUIState,
  toggleAudienceDeleteDialog,
  toggleAudienceExporting,
  toggleDimensionDialog,
  toggleExportDialog,
  toggleGroupDeleteDialog,
  toggleDraftStateDialog,
  toggleDuplicateAudienceDialog,
  toggleGroupNameDialog,
  searchQuery,
  updateCurrentAudience,
  updateCurrentDimensions,
  updateCurrentDimensionGroups,
  updateCurrentConditions,
  updateCurrentSurvey,
  updateCurrentAnswers,
  updateAudiencesEntities,
  updateConditionsEntities,
  updateDimensionsEntities,
  updateDimensionGroupsEntities,
  updateAudiences,
  updateCondition,
  updateConditions,
  updateCurrentGroup,
  updateCurrentGroupAudiences,
  updateDimension,
  updateDimensionGroup,
  updateGroup,
  updateGroups,
  updateEmails,
  updateStates,
  updateAmount,
  updateAmountCustom,
  updateAmountOperator,
  updateCount,
  updateCountCustom,
  updateCountOperator,
  updatingDimension,
  updateTimeframe,
  updateTimeframeEnd,
  updateTimeframeOperator,
  updateTimeframeStart,
  updatingTotalAudience,
  query,
  queryTotalAudienceSize,
  removeAudience,
  removeDimensionsAndConditions,
  removeDimensionGroups,
  removeGroup,
  removeNotification,
  removeStates,
  resetCurrentAnswers,
  querySurveys,
  setSurveyLoading,
  updateSurveys,
  updateCurrentDimension,
  updateCurrentResponses,
  updateCurrentCount,
  resetCurrentConditions,
  populateCurrentAnswers,
  updateSurveyMap,
} = audienceSlice.actions;

export default audienceSlice.reducer;
