import React, { useMemo } from 'react';
import { Box, CircularProgress } from '@material-ui/core';
import { Button } from '@klover/attain-design-system';
import { MODEL_YOUR_AUDIENCE } from '../../content';
import { PaperPlaneRight } from '@phosphor-icons/react';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';

// Define prop types
interface ModelAudienceButtonProps {
  modelYourAudience: () => void;
  isModeling: boolean;
  lalModelStatus: string;
  lalModelCompletedAt: any;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      height: '80vh',
    },
    text: {
      textTransform: 'none',
      fontFamily: 'Calibre-Regular',
      fontWeight: 600,
      fontSize: 18,
      marginRight: 8,
    },
    icon: {
      marginLeft: 8,
    },
    caption: {
      marginTop: theme.spacing(1),
      fontFamily: 'Calibre-Regular',
      fontSize: 14,
    },
  })
);

const ModelAudienceButton: React.FC<ModelAudienceButtonProps> = (props) => {
  const classes = useStyles();
  const { modelYourAudience, isModeling, lalModelStatus, lalModelCompletedAt } =
    props;

  const lastDateTimeModeledAt = useMemo(() => {
    if (lalModelCompletedAt) {
      const date = new Date(lalModelCompletedAt); // Convert to a Date object
      // Format the date as desired
      return date.toLocaleString();
    }
    return 'Audience never modeled';
  }, [lalModelCompletedAt]);

  return (
    <Box className={classes.container}>
      <Button
        variant="contained"
        onClick={modelYourAudience}
        disabled={isModeling || lalModelStatus === 'IN_PROGRESS'} // Disable button while modeling
      >
        <>
          {MODEL_YOUR_AUDIENCE}
          {isModeling || lalModelStatus === 'IN_PROGRESS' ? (
            <CircularProgress
              size={24}
              color="inherit"
              style={{ marginLeft: 8 }}
            />
          ) : (
            <PaperPlaneRight size={22} className={classes.icon} />
          )}
        </>
      </Button>
      <span className={classes.caption}>
        Last Modified: {lastDateTimeModeledAt}
      </span>
    </Box>
  );
};

export default ModelAudienceButton;
