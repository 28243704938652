import Avatar from '@mui/material/Avatar';
import LogRocket from 'logrocket';
import { ILoginReducer } from 'types/types';
import { Link } from 'react-router-dom';
import { Menu, MenuItem } from '@klover/attain-design-system';
import { Notification } from 'interfaces/notifications';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';

import kdsLogo from '../../../public/logo1.png';
import { Badge, createStyles, makeStyles } from '@material-ui/core';
import {
  DocumentData,
  QuerySnapshot,
  collection,
  onSnapshot,
} from 'firebase/firestore';

import { addNotification } from 'pages/audienceManager/slice';
import { deleteNotification } from 'pages/notifications/slice';
import {
  setZipCount,
  setZipsFileUploadingState,
} from 'pages/offerWallManager/slice';

import { FsCollections } from 'constants/index';
import { Notifications as NotificationsIcon } from '@material-ui/icons';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import { fs } from 'utils/firebase';
import { logOutAction } from 'pages/auth/actions';
import { useAppSelector } from 'redux/hooks';
import { useUser } from 'reactfire';
import * as Styled from './GlobalHeader.styles';

const useStyles = makeStyles(() =>
  createStyles({
    kdsName: {
      color: 'white',
      fontSize: 24,
      fontWeight: 'bold',
      fontFamily: 'Calibre-Semibold, sans-serif',
    },
    link: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      gap: 5,
    },
    notificationBadge: {
      padding: '8px',
      marginLeft: '-6px',
      color: '#DADEE2 !important',
      '&:hover': {
        color: 'white',
      },
      '&:focus': {
        color: 'white',
      },
    },
    notification: {
      cursor: 'pointer',
    },
  })
);

const GlobalHeader = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const history = useHistory();
  const { data: user } = useUser();
  const currentUser = user || { email: '' };

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const authData: ILoginReducer = useSelector(
    (state: RootStateOrAny) => state.loginReducer
  );

  const currentNotifications = useAppSelector((state) => {
    return state.notificationsReducer.currentNotifications;
  });

  const unreadNotifications = currentNotifications?.reduce(
    (accumulator: number, currVal: Notification) => {
      return currVal.read ? accumulator : accumulator + 1;
    },
    0
  );

  // firestore listeners
  useEffect(() => {
    const notificationsRef = collection(fs, FsCollections.NOTIFICATIONS);
    const toastersRef = collection(fs, FsCollections.TOASTERS);
    const unsubToasters = onSnapshot(toastersRef, onToasterSnapshot);
    const unsubNotifications = onSnapshot(
      notificationsRef,
      onNotificationsSnapshot
    );

    LogRocket.identify(user.uid, {
      email: currentUser.email,
    });
    return () => {
      unsubToasters();
      unsubNotifications();
    };
  }, []);

  const onToasterSnapshot = (snapshot: QuerySnapshot<DocumentData>) => {
    const data = snapshot.docChanges()[0]?.doc.data();
    const id = snapshot.docChanges()[0]?.doc.id;
    const type = snapshot.docChanges()[0]?.type;
    if (type === 'added') {
      if (currentUser.email === data.email) {
        dispatch(
          addNotification({
            state: data.state.toLowerCase(),
            message: data.message,
          })
        );
      }
    }
    dispatch(deleteNotification(id));
  };

  const onNotificationsSnapshot = (snapshot: QuerySnapshot<DocumentData>) => {
    const data = snapshot.docChanges()[0]?.doc.data();
    const type = snapshot.docChanges()[0]?.type;
    if (type === 'added') {
      if (Object.hasOwn(data.refObj, 'zipsTotal')) {
        dispatch(setZipsFileUploadingState(data.state));
        dispatch(setZipCount(data.refObj.zipsTotal));
      }
    }
  };

  const handleClick = (e: any) => {
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const logOut = () => dispatch(logOutAction());

  const handleSignOut = () => {
    if (authData.data.res.uid) {
      logOut();
    }
  };

  const open = Boolean(anchorEl);
  const avatarText = currentUser.email?.slice(0, 2).toUpperCase();
  const id = open ? 'simple-Menu' : undefined;
  return (
    <Styled.Wrapper>
      <Link to="/main" className={classes.link}>
        <img src={kdsLogo} alt="kds-logo" width={28} height={28} />
        <span className={classes.kdsName}>Klover Data Suite</span>
      </Link>

      <Styled.Actions>
        <Badge
          badgeContent={unreadNotifications}
          overlap="circular"
          className={classes.notificationBadge}
          style={{ display: 'none' }}
        >
          <NotificationsIcon
            className={classes.notification}
            onClick={() => {
              history.push('/main/notifications');
            }}
          />
        </Badge>

        <span
          role="button"
          aria-describedby={id}
          onClick={(e) => handleClick(e)}
        >
          <Avatar
            style={{
              backgroundColor: '#B8F4F1',
              color: '#1A171C',
              fontSize: 18,
              fontFamily: 'Calibre-Semibold, sans-serif',
              fontWeight: 600,
              lineHeight: 28,
              display: 'flex',
              height: 36,
              justifyContent: 'center',
              width: 36,
            }}
          >
            {avatarText}
          </Avatar>
        </span>

        <Menu
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
          <MenuItem onClick={() => handleSignOut()}>Logout</MenuItem>
        </Menu>
      </Styled.Actions>
    </Styled.Wrapper>
  );
};

export default GlobalHeader;
