import axios from 'axios';
import camelizeKeys from 'utils/camelizeKeys';
import { API_BASE_ADDRESS } from '../variables';
import {
  Audience,
  AudienceGroup,
  addGroup,
  createGroup,
  deleteGroup,
  fetchEmails,
  fetchGroup,
  fetchGroups,
  removeGroup,
  saveGroup,
  setEmailIsLoadingState,
  setGroupDeletingState,
  setGroupLoadingState,
  setGroupSavingState,
  setGroupsLoadingState,
  updateAudiences,
  updateCurrentGroup,
  updateEmails,
  updateGroup,
  updateGroups,
} from '../slice';
import { PayloadAction } from '@reduxjs/toolkit';
import { auth } from 'utils/firebase';
import { call, put, takeLatest } from 'redux-saga/effects';

const API_ENDPOINT = `${API_BASE_ADDRESS}/audience-manager/groups`;
const EMAIL_ENDPOINT = `${process.env.API_BASE_ADDRESS}/audience-manager/emails`;

interface AudienceGroupParams {
  id: string;
  name: string;
}

function handleCreateGroup(group: AudienceGroup) {
  const params: AudienceGroupParams = {
    id: group.id,
    name: group.name,
  };
  return auth.currentUser.getIdTokenResult().then((res) => {
    const authorizedAxiosInstance = axios.create({
      headers: {
        Authorization: `Bearer ${res.token}`,
      },
    });
    return authorizedAxiosInstance.post(API_ENDPOINT, params);
  });
}

function handleDeleteGroup(group: AudienceGroup) {
  return auth.currentUser.getIdTokenResult().then((res) => {
    const authorizedAxiosInstance = axios.create({
      headers: {
        Authorization: `Bearer ${res.token}`,
      },
    });
    return authorizedAxiosInstance.delete(`${API_ENDPOINT}/${group.id}`);
  });
}

function handleFetchGroup(group: AudienceGroup) {
  return auth.currentUser.getIdTokenResult().then((res) => {
    const authorizedAxiosInstance = axios.create({
      headers: {
        Authorization: `Bearer ${res.token}`,
      },
    });
    return authorizedAxiosInstance.get(`${API_ENDPOINT}/${group.id}`);
  });
}

function handleFetchGroups() {
  return auth.currentUser.getIdTokenResult().then((res) => {
    const authorizedAxiosInstance = axios.create({
      headers: {
        Authorization: `Bearer ${res.token}`,
      },
    });
    return authorizedAxiosInstance.get(API_ENDPOINT);
  });
}

function handleFetchEmails() {
  return auth.currentUser.getIdTokenResult().then((res) => {
    const authorizedAxiosInstance = axios.create({
      headers: {
        Authorization: `Bearer ${res.token}`,
      },
    });
    return authorizedAxiosInstance.get(EMAIL_ENDPOINT);
  });
}

function handleUpdateGroup(group: AudienceGroup) {
  const params: AudienceGroupParams = {
    id: group.id,
    name: group.name,
  };
  return auth.currentUser.getIdTokenResult().then((res) => {
    const authorizedAxiosInstance = axios.create({
      headers: {
        Authorization: `Bearer ${res.token}`,
      },
    });
    return authorizedAxiosInstance.put(`${API_ENDPOINT}/${group.id}`, params);
  });
}

function isValidGroup(result: {
  status: number;
  data: { id: any; name: any; audiences: any };
}) {
  return (
    result &&
    result.status &&
    result.status === 200 &&
    result.data &&
    result.data.id &&
    result.data.name
  );
}

function* watchCreateGroup(action: PayloadAction<AudienceGroup>) {
  try {
    yield put(setGroupSavingState({ state: 'loading' }));
    const result = yield call(handleCreateGroup, action.payload);

    if (isValidGroup(result)) {
      const group: AudienceGroup = {
        ...result.data,
        audiences: [],
      };

      yield put(addGroup(group));
      yield put(updateCurrentGroup(group));
      window.history.replaceState(
        null,
        '',
        `${window.location.pathname}?groupId=${group.id}`
      );
      yield put(
        setGroupSavingState({
          state: 'done',
          message: 'Group successfully created!',
        })
      );
    } else {
      throw 'Failed to create group';
    }
  } catch (e) {
    yield put(setGroupSavingState({ state: 'failed', message: e }));
  }
}

function* watchDeleteGroup(action: PayloadAction<AudienceGroup>) {
  try {
    yield put(setGroupDeletingState({ state: 'loading' }));
    const result = yield call(handleDeleteGroup, action.payload);

    if (result && result.status && result.status === 200) {
      yield put(removeGroup(action.payload));
      yield put(
        setGroupDeletingState({
          state: 'done',
          message: `${action.payload.name} and its audiences have been deleted`,
        })
      );
      yield put(fetchGroups());
      window.history.replaceState(
        null,
        '',
        `${window.location.pathname}?groupId=all`
      );
    } else {
      throw `Failed to delete group ${action.payload.name}`;
    }
  } catch (e) {
    yield put(setGroupDeletingState({ state: 'failed', message: e }));
  }
}

function* watchFetchGroup(action: PayloadAction<AudienceGroup>) {
  try {
    yield put(setGroupLoadingState({ state: 'loading' }));
    const result = yield call(handleFetchGroup, action.payload);
    if (isValidGroup(result)) {
      const group: AudienceGroup = {
        ...result.data,
        audiences: [],
      };

      const audiences: Audience[] = [];
      if (result.data.audiences) {
        result.data.audiences.forEach((elem) => {
          const audience: Audience = {
            ...camelizeKeys(elem),
            dimensions: [],
            dimensionGroups: [],
            totalcount: result.data.audiences.length,
          };
          audiences.push(audience);
          group.audiences.push(audience.id);
        });

        yield put(updateAudiences(audiences));
      }

      yield put(updateGroup(group));
      yield put(updateCurrentGroup(group));
      yield put(
        setGroupLoadingState({
          state: 'done',
        })
      );
    } else {
      throw `Failed to load group ${action.payload.name}`;
    }
  } catch (e) {
    yield put(setGroupLoadingState({ state: 'failed', message: e }));
  }
}

function* watchFetchGroups() {
  try {
    yield put(setGroupsLoadingState({ state: 'loading' }));
    const result = yield call(handleFetchGroups);

    const groups: AudienceGroup[] = [];
    result.data.forEach((e: { id: any; name: any; audiences: any }) => {
      const group: AudienceGroup = {
        ...e,
        audiences: [],
      };
      groups.push(group);
    });

    yield put(updateGroups(groups));
    yield put(setGroupsLoadingState({ state: 'done' }));
  } catch (e) {
    console.log('error:', e);
    yield put(setGroupsLoadingState({ state: 'failed', message: e }));
  }
}

function* watchFetchEmails() {
  try {
    yield put(setEmailIsLoadingState({ state: 'loading' }));
    const result = yield call(handleFetchEmails);

    const allUsers: any = [{ label: 'All Emails', id: 'all' }];
    result.data.map((user: any) => {
      allUsers.push({
        label: user.created_by_email,
        id: user.created_by_email,
      });
    });

    yield put(updateEmails(allUsers));
    yield put(setEmailIsLoadingState({ state: 'done' }));
  } catch (e) {
    console.log('error:', e);
    yield put(setEmailIsLoadingState({ state: 'failed', message: e }));
  }
}

function* watchUpdateGroup(action: PayloadAction<AudienceGroup>) {
  try {
    yield put(setGroupSavingState({ state: 'loading' }));
    const result = yield call(handleUpdateGroup, action.payload);

    if (isValidGroup(result)) {
      const group: AudienceGroup = {
        ...result.data,
        audiences: action.payload.audiences,
      };

      yield put(updateGroup(group));
      yield put(updateCurrentGroup(group));
      yield put(
        setGroupSavingState({
          state: 'done',
          message: 'Group successfully updated!',
        })
      );
    } else {
      throw 'Failed to update group';
    }
  } catch (e) {
    yield put(setGroupSavingState({ state: 'failed', message: e }));
  }
}

export default function* watchAll() {
  yield takeLatest<any>(createGroup.type, watchCreateGroup);
  yield takeLatest<any>(deleteGroup.type, watchDeleteGroup);
  yield takeLatest<any>(fetchGroup.type, watchFetchGroup);
  yield takeLatest<any>(fetchGroups.type, watchFetchGroups);
  yield takeLatest<any>(fetchEmails.type, watchFetchEmails);
  yield takeLatest<any>(saveGroup.type, watchUpdateGroup);
}
