import { GET_LIVERAMP_INTEGRATIONS } from '../queries';
import { useQuery } from '@apollo/client';

/**
 * Fetch es the list of Liveramp intigrations.
 */
export const useLiveRampIntigrations = ({
  distributionManagerId,
  distributionManagerName,
  integrationId,
  integrationName,
}) => {
  const { loading, error, data, refetch } = useQuery(
    GET_LIVERAMP_INTEGRATIONS,
    {
      variables: {
        distributionManagerId,
        distributionManagerName,
        integrationId,
        integrationName,
      },
    }
  );

  return {
    loading,
    error,
    liveRampIntegrations:
      data?.liveRampIntegrations?.integrations?.map((x) => {
        return {
          label: x.integrationName,
          id: x.integrationId,
        };
      }) || [],
    liveRampDistributionManagers:
      data?.liveRampIntegrations?.distributionManagers || [],
    refetch,
  };
};

export default useLiveRampIntigrations;
